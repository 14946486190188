import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          about: "About",
          contact: "Contact",
          products: "Products",
          referances: "E-Catalog",
          prev: "Prev",
          next: "Next",
          download: "Download PDF",
          allRightsReserved: "All rights reserved.",
          description: {
            subDesp: "We have been serving in Gaziantep since 2005",
            subHeader: "Haspak Mendil",
            subHighLight: "Always One Step Ahead.",
            subDescp2: "17 Over Years of Experience",
            subDescp3:
              "Haspak has been producing private label handkerchiefs as requested by our customers since 2005.",
            subLongDescp:
              "HASPAK papermaking industry.and trading. Limited Company  serves a distinguished customer portfolio with the  product palette consisting of 1st Quality Scented Tissue Box, Individual Paper Napkin, Bag Tissue, Pocket Handkerchief and Contract manufacturing which are produced with its high technology and experienced team. 70% of those products are sent overseas and the rest  30% are domestic.",
            subLongDescp2:
              "HASPAK papermaking industry.and trading  Limited Company has always chosen the best and the highest quality as its target, as a result of the R&D Studies it has carried out day by day without sacrificing its 100% cellulose quality since the first day it entered the cleaning paper sector. Our aim is to bring this quality to wider masses and it will be.",
          },

          tabs: {
            kutuMendil: "Facial Tissue",
            posetMendil: "Wipes",
            cepMendil: "Pocket Wipes",
            cantaMendil: "Bag sachet wipes",
            pratikMendil: "Practical wipes",
            allProducts: "All Products",
            aparat: "Plastic Box",
          },
          productPage: {
            subHeader: "Haspak Napkin",
            subDescp: "Haspak ",
            subMeasurement: "Measurement",
            subPaper: "Paper",
          },
          productsDescp: {
            paperPieces: "Product Paper Pieces",
            piecesInBox: "Pieces In Box",
            paperType: "Paper Type",
            leafSize: "Leaf Size",
            cellulose: "Cellulose",
            sheets: "Sheets",
            boxType: "Box Type",
            plastic: "Plastic",
            paper: "Paper",
            ply: "Ply",
          },
          productName: {
            kutuMendil: "Facial Tissue",
            kutuMendilMetalized: "Metallized Facial Tissue",
            fourShrink: "4 Pcs Shrink Tissue Box",
            kromeKutuMendil: "Soft Facial Tissue",
            kromeMendil: "Soft Facial Tissue",
            posetMendil: "Sachet Wipes",
            cantaMendil: "Bag sachet wipes",
            pratikMendil: "Practical wipes",

            cepMendil: "Pocket Wipes",
            pecete: "Napkin",
            cubeFacialTissue: "Cube Facial Tissue",
            aparat: "Plastic Box",
          },

          kutuMendilDescps: {
            subHeader: "Haspak Kutu Mendil",
            subPiece: "Piece",
            subMeasurement: "Measurement",
            subPaper: "Paper",
            subDesp: "We have been serving in Gaziantep since 2005.",
          },
          aboutUs: {
            subHeader: "About Us",
            subDesp:
              "HASPAK papermaking industry.and trading. Limited Company  serves a distinguished customer portfolio with the  product palette consisting of 1st Quality Scented Tissue Box, Individual Paper Napkin, Bag Tissue, Pocket Handkerchief and Contract manufacturing which are produced with its high technology and experienced team. 70% of those products are sent overseas and the rest  30% are domestic. HASPAK papermaking industry.and trading  Limited Company has always chosen the best and the highest quality as its target, as a result of the R&D Studies it has carried out day by day without sacrificing its 100% cellulose quality since the first day it entered the cleaning paper sector. Our aim is to bring this quality to wider masses and it will be. HASPAK papermaking industry.and trading. Limited Company was established in Gaziantep in 2005 on an area of ​​8000 m2, and it produced Scented Box Wipes , one-by-one taken napkin and Flat Pocket Handkerchief, which is a first in Turkey. In the last 3 years, our company has developed its product portfolio and vision day by day, and has always chosen to deliver better and quality products to the consumer. And this mission has enabled our company to be among the favorite companies followed by similar companies in Turkey.",
          },
          contactUs: {
            subHeader: "Contact",
            header: "Contact us",
            headerDesp: "Fill in the form below to reach us.",
            contactEmail: "Your E-Mail ",
            contactName: "Your Name",
            contactMessage: "Text",
            contactSubject: "Subject",
            contactLocation: "Our addresses",
            contactIstanbul: "İstanbul Office",
            contactGaziantep: "Gaziantep Factory",
            send: "Send",
          },
        },
      },
      tr: {
        translation: {
          about: "Hakkımızda",
          contact: "İletişim",
          products: "Ürünler",
          referances: "E-Katalog",
          allRightsReserved: "Tüm hakları saklıdır.",
          prev: "Önceki",
          next: "Sonraki",
          download: "PDF'i İndir",
          description: {
            subDesp: "2005'den Bugüne Haspak Ailesi Olarak Hizmet Vermekteyiz",
            subHeader: "Haspak Mendil",
            subHighLight: "Hep Bir Adım Önde.",
            subDescp2: "17 Yılı Aşkın Tecrübesiyle",
            subDescp3:
              "Haspak, 2005'ten bu yana müşterilerimizin istediği özel etiketli mendil üretimini yapmaktadır",
            subLongDescp:
              "HASPAK Kağıtçılık San.ve Tic. Ltd. Şti Yüksek teknolojisi, tecrübeli ekibi ile ürettiği 1. Kalite Kokulu Kutu Mendil, Tek Tek Al Peçete, Poşet Mendil, Cep Mendil ve Fason üretimi ile oluşan ürün paleti %70’ i yurt dışı, % 30’u yurt içi olmak üzere Seçkin bir müşteri portföyüne hizmet vermektedir.",
            subLongDescp2:
              "HASPAK Kağıtçılık San.ve Tic. Ltd. Şti Temizlik kâğıdı sektörüne ilk girdiği günden bu yana %100 selüloz olan kalitesinden asla ödün vermeden her geçen gün yapmış olduğu Arge Çalışmaları neticesinde hep en iyiyi ve kaliteliyi hedef olarak seçmiştir. Amacımız bu Kaliteyi daha geniş kitlelere ulaştırmak olacaktır.",
          },
          tabs: {
            kutuMendil: "Kutu Mendil",
            posetMendil: "Poşet Mendil",
            cepMendil: "Cep Mendil",
            allProducts: "Tüm Ürünler",
            aparat: "Dispenser Aparat",
          },
          productPage: {
            subHeader: "Haspak Mendil",
            subDescp: "Haspak ",
            subMeasurement: "Ölçü",
            subPaper: "Kağıt",
          },

          productsDescp: {
            paperPieces: "Ürün Yaprak Sayısı",
            piecesInBox: "Koli iç adeti",
            paperType: "Kağıt Yapısı",
            leafSize: "Yaprak Ölçüsü",
            cellulose: "selüloz",
            sheets: "Yaprak",
            boxType: "Kutu Yapısı",
            plastic: "Plastik",
            paper: "Kağıt",
            ply: "Kat",
          },
          productName: {
            kutuMendil: "Kutu Mendil",
            kutuMendilMetalized: "Metalize Kutu Mendil",
            fourShrink: "4 lü Shrink Kutu Mendil",
            kromeKutuMendil: "Soft Kutu Mendil",
            posetMendil: "Poşet Mendil",
            cantaMendil: "Çanta Poşet Mendil",
            cepMendil: "Cep Mendil",
            pratikMendil: "Pratik Mendil",
            pecete: "Peçete",
            cubeFacialTissue: "Küp Kutu Mendil",
            aparat: "Dispenser Aparat",
          },
          kutuMendilDescps: {
            subHeader: "Haspak Kutu Mendil",
            subPiece: "Adet",
            subMeasurement: "Ölçü",
            subPaper: "Kağıt",
            subDesp: "2005 yılından Beri Gaziantep'te hizmet vermekteyiz.",
          },
          aboutUs: {
            subHeader: "Hakkımızda",
            subDesp:
              "HASPAK Kagitcilik San.ve Tic. Ltd. Sti 2005 yilinda Gaziantep’te  8000 m2 alan üzerinde kurulmuş olup, Kokulu Kutu Mendil üretimi ve Türkiye’de bir ilk olan Tek Tek Al peçete’yi ve Yassı Cep Mendi'i üretmiştir. Şirketimiz son 3 yıl içerisinde ürün portföyünü ve vizyonunu her geçen gün daha da geliştirerek tüketiciye her zaman daha iyiyi ve kaliteli ürün ulaştırmayı hedef olarak seçmiştir. Ve bu misyon firmamızın Türkiye’de ki benzer üretim yapan firmaların takip ettiği gözde firmaları arasında yer almasını sağlamıştır. HASPAK Kağıtçılık San.ve Tic. Ltd. Şti Yüksek teknolojisi, tecrübeli ekibi ile ürettiği 1. Kalite Kokulu Kutu Mendil, Tek Tek Al Peçete, Poşet Mendil, Cep Mendil ve Fason üretimi ile oluşan ürün paleti %70’ i yurt dışı, % 30’u yurt içi olmak üzere Seçkin bir müşteri portföyüne hizmet vermektedir. HASPAK Kağıtçılık San.ve Tic. Ltd. Şti Temizlik kâğıdı sektörüne ilk girdiği günden bu yana %100 selüloz olan kalitesinden asla ödün vermeden her geçen gün yapmış olduğu Arge Çalışmaları neticesinde hep en iyiyi ve kaliteliyi hedef olarak seçmiştir. Amacımız bu Kaliteyi daha geniş kitlelere ulaştırmak olacaktır.",
          },
          contactUs: {
            subHeader: "İletişim",
            header: "Bize Ulaşın",
            headerDesp: "Bize Ulaşmak İçin Aşağıdaki Formu Doldurunuz.",
            contactEmail: "E-Posta Adresiniz",
            contactName: "Adınız",
            contactMessage: "Mesajınız",
            contactSubject: "Konu",
            contactLocation: "Adreslerimiz",
            contactIstanbul: "İstanbul Şube",
            contactGaziantep: "Gaziantep Fabrika",
            send: "Gönder",
          },
        },
      },
    },
  });

export default i18n;
