import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { useTranslation } from "react-i18next";
import ModalImage from "react-modal-image";

import Photos from "components/photos/Photos.js";

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${(props) => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(
  motion.div
)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(
  motion.a
)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm rounded-full`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default () => {
  const { t } = useTranslation();
  const tabs = {
    allProducts: [
      {
        imageSrc: Photos.mendil2513,
        title: `${t("productName.cubeFacialTissue")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 80 ${t(
          "productsDescp.sheets"
        )} 3 ${t("productsDescp.ply")}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 20`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },

      {
        imageSrc: Photos.mendil2514,
        title: `${t("productName.cubeFacialTissue")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 80 ${t(
          "productsDescp.sheets"
        )} 3 ${t("productsDescp.ply")}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 20`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendil2515,
        title: `${t("productName.cubeFacialTissue")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 80 ${t(
          "productsDescp.sheets"
        )} 3 ${t("productsDescp.ply")}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 20`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },

      {
        imageSrc: Photos.mendil2517,
        title: `${t("productName.cubeFacialTissue")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 80 ${t(
          "productsDescp.sheets"
        )} 3 ${t("productsDescp.ply")}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 20`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendil1000,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 100 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,
      },
      {
        imageSrc: Photos.mendil2522,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 100 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,
      },
      {
        imageSrc: Photos.mendil2523,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 100 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,
      },
      {
        imageSrc: Photos.mendil2524,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 100 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,
      },
      {
        imageSrc: Photos.mendil1015,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 160 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,
      },
      {
        imageSrc: Photos.mendil3008,
        title: `${t("productName.kutuMendilMetalized")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 200 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,
      },
      {
        imageSrc: Photos.mendil2507,
        title: `${t("productName.kutuMendilMetalized")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 200 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,
      },
      {
        imageSrc: Photos.mendil3502,
        title: `${t("productName.kutuMendilMetalized")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 200 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,
      },

      {
        imageSrc: Photos.mendil2501,
        title: `${t("productName.kutuMendilMetalized")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 200 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,
        url: "#",
      },
      {
        imageSrc: Photos.mendil2503,
        title: `${t("productName.kutuMendilMetalized")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 200 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },

      {
        imageSrc: Photos.mendil2504,
        title: `${t("productName.kutuMendilMetalized")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 200 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendil2505,
        title: `${t("productName.kutuMendilMetalized")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 80 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },

      {
        imageSrc: Photos.mendil2509,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 70 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },

      {
        imageSrc: Photos.mendil2510,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 70 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendil2511,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 70 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendil2512,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 70 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendil2516,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 80 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendilKutu3,
        title: `${t("productName.fourShrink")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 70 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendilKutu4,
        title: `${t("productName.fourShrink")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 100 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendil3504,
        title: `${t("productName.kutuMendilMetalized")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 80 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendil2506,
        title: `${t("productName.kutuMendilMetalized")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 80  ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendil2509,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 70 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendil1001,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 250 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 36`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },
      {
        imageSrc: Photos.mendilKutu2,
        title: `${t("productName.cantaMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 250 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 36`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },
      {
        imageSrc: Photos.mendilKutu1,
        title: `${t("productName.cantaMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 300 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },
      {
        imageSrc: Photos.mendil1002,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 250 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },
      {
        imageSrc: Photos.mendil1003,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 250 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },

      {
        imageSrc: Photos.mendil1004,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 200 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 133x190mm`,
      },
      {
        imageSrc: Photos.mendil1005,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 200 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 133x190mm`,
      },
      {
        imageSrc: Photos.mendil1006,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 200 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 133x190mm`,
      },
      {
        imageSrc: Photos.mendil1007,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 150 ${t(
          "productsDescp.sheets"
        )} 3 ${t("productsDescp.ply")}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 190x190mm`,
      },
      {
        imageSrc: Photos.mendil1008,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 150 ${t(
          "productsDescp.sheets"
        )} 3 ${t("productsDescp.ply")}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 190x190mm`,
      },
      {
        imageSrc: Photos.mendil1009,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 250 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },
      {
        imageSrc: Photos.mendil1010,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 250 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 36`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },
      {
        imageSrc: Photos.mendil1011,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 250 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 36`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },
      {
        imageSrc: Photos.mendil1012,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 250 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 36`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },
      {
        imageSrc: Photos.mendil1013,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 300 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },
      {
        imageSrc: Photos.mendil1014,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 300 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },
      {
        imageSrc: Photos.mendil2003,
        title: `${t("productName.cepMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 10 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 480`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 200x190mm`,
      },

      {
        imageSrc: Photos.mendil2004,
        title: `${t("productName.cepMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 10 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 480`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 200x190mm`,
      },
      {
        imageSrc: Photos.mendil2005,
        title: `${t("productName.pratikMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 50 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendil2006,
        title: `${t("productName.aparat")}`,
        paperPieces: `${t("productsDescp.boxType")}: ${t(
          "productsDescp.plastic"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 20`,
      },

      {
        imageSrc: Photos.mendil2007,
        title: `${t("productName.aparat")}`,
        paperPieces: `${t("productsDescp.boxType")}: ${t(
          "productsDescp.plastic"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 20`,
      },
      {
        imageSrc: Photos.mendil2008,
        title: `${t("productName.aparat")}`,
        paperPieces: `${t("productsDescp.boxType")}: ${t(
          "productsDescp.plastic"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 20`,
      },

      {
        imageSrc: Photos.mendil1101,
        title: `${t("productName.aparat")}`,
        paperPieces: `${t("productsDescp.boxType")}: ${t(
          "productsDescp.plastic"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 20`,
      },
      {
        imageSrc: Photos.mendil2502,
        title: `${t("productName.aparat")}`,
        paperPieces: `${t("productsDescp.boxType")}: ${t(
          "productsDescp.plastic"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 20`,
      },
      {
        imageSrc: Photos.mendil3503,
        title: `${t("productName.aparat")}`,
        paperPieces: `${t("productsDescp.boxType")}: ${t(
          "productsDescp.plastic"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 20`,
      },
    ],
    kutuMendil: [
      {
        imageSrc: Photos.mendil1000,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 100 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,
      },
      {
        imageSrc: Photos.mendil2522,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 100 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,
      },
      {
        imageSrc: Photos.mendil2523,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 100 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,
      },
      {
        imageSrc: Photos.mendil2524,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 100 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,
      },
      {
        imageSrc: Photos.mendilKutu3,
        title: `${t("productName.fourShrink")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 80 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendilKutu4,
        title: `${t("productName.fourShrink")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 100 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendil1015,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 160 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,
      },
      {
        imageSrc: Photos.mendil3008,
        title: `${t("productName.kutuMendilMetalized")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 200 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,
      },
      {
        imageSrc: Photos.mendil2507,
        title: `${t("productName.kutuMendilMetalized")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 200 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,
      },
      {
        imageSrc: Photos.mendil3502,
        title: `${t("productName.kutuMendilMetalized")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 200 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,
      },

      {
        imageSrc: Photos.mendil2501,
        title: `${t("productName.kutuMendilMetalized")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 200 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,
        url: "#",
      },
      {
        imageSrc: Photos.mendil2503,
        title: `${t("productName.kutuMendilMetalized")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 200 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },

      {
        imageSrc: Photos.mendil2504,
        title: `${t("productName.kutuMendilMetalized")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 200 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendil2505,
        title: `${t("productName.kutuMendilMetalized")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 80 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendil2506,
        title: `${t("productName.kutuMendilMetalized")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 80 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendil2509,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 70 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },

      {
        imageSrc: Photos.mendil2510,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 80 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendil2511,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 80 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendil2512,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 70 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendil2516,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 70 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendil3504,
        title: `${t("productName.kutuMendilMetalized")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 70 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendil2506,
        title: `${t("productName.kutuMendilMetalized")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 80  ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
      {
        imageSrc: Photos.mendil2509,
        title: `${t("productName.kromeKutuMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 70 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 24`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 220x190mm`,

        url: "#",
      },
    ],

    posetMendil: [
      {
        imageSrc: Photos.mendil1001,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 250 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },

      {
        imageSrc: Photos.mendil1002,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 250 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },
      {
        imageSrc: Photos.mendil1003,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 250 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },

      {
        imageSrc: Photos.mendil1004,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 200 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 133x190mm`,
      },
      {
        imageSrc: Photos.mendil1005,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 200 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 133x190mm`,
      },
      {
        imageSrc: Photos.mendil1006,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 200 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 133x190mm`,
      },
      {
        imageSrc: Photos.mendil1007,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 150 ${t(
          "productsDescp.sheets"
        )} 3 ${t("productsDescp.ply")}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 190x190mm`,
      },
      {
        imageSrc: Photos.mendil1008,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 150 ${t(
          "productsDescp.sheets"
        )} 3 ${t("productsDescp.ply")}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 190x190mm`,
      },
      {
        imageSrc: Photos.mendil1009,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 250 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 36`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },
      {
        imageSrc: Photos.mendil1010,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 250 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 36`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },
      {
        imageSrc: Photos.mendil1011,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 250 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 36`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },
      {
        imageSrc: Photos.mendil1012,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 250 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 36`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },
      {
        imageSrc: Photos.mendil1013,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 300 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },
      {
        imageSrc: Photos.mendil1014,
        title: `${t("productName.posetMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 300 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },
      {
        imageSrc: Photos.mendilKutu1,
        title: `${t("productName.cantaMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 300 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },
      {
        imageSrc: Photos.mendilKutu2,
        title: `${t("productName.cantaMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 300 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 36`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,
      },
    ],
    cepMendil: [
      {
        imageSrc: Photos.mendil2003,
        title: `${t("productName.cepMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 10 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 480`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 200x190mm`,
      },

      {
        imageSrc: Photos.mendil2004,
        title: `${t("productName.cepMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 10 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 480`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 200x190mm`,
      },
      {
        imageSrc: Photos.mendil2005,
        title: `${t("productName.pratikMendil")}`,
        paperPieces: `${t("productsDescp.paperPieces")}: 50 ${t(
          "productsDescp.sheets"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 30`,
        paperType: `${t("productsDescp.paperType")}: %100 ${t(
          "productsDescp.cellulose"
        )}`,
        leafSize: `${t("productsDescp.leafSize")}: 146x190mm`,

        url: "#",
      },
    ],

    aparat: [
      {
        imageSrc: Photos.mendil2006,
        title: `${t("productName.aparat")}`,
        paperPieces: `${t("productsDescp.boxType")}: ${t(
          "productsDescp.plastic"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 20`,
      },

      {
        imageSrc: Photos.mendil2007,
        title: `${t("productName.aparat")}`,
        paperPieces: `${t("productsDescp.boxType")}: ${t(
          "productsDescp.plastic"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 20`,
      },
      {
        imageSrc: Photos.mendil2008,
        title: `${t("productName.aparat")}`,
        paperPieces: `${t("productsDescp.boxType")}: ${t(
          "productsDescp.plastic"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 20`,
      },

      {
        imageSrc: Photos.mendil1101,
        title: `${t("productName.aparat")}`,
        paperPieces: `${t("productsDescp.boxType")}: ${t(
          "productsDescp.plastic"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 20`,
      },
      {
        imageSrc: Photos.mendil2502,
        title: `${t("productName.aparat")}`,
        paperPieces: `${t("productsDescp.boxType")}: ${t(
          "productsDescp.plastic"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 20`,
      },
      {
        imageSrc: Photos.mendil3503,
        title: `${t("productName.aparat")}`,
        paperPieces: `${t("productsDescp.boxType")}: ${t(
          "productsDescp.plastic"
        )}`,
        piecesInBox: `${t("productsDescp.piecesInBox")}: 20`,
      },
    ],
  };

  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{t("products")}</Header>
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl
                key={index}
                active={activeTab === tabName}
                onClick={() => setActiveTab(tabName)}
              >
                {t(`tabs.${tabName}`)}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale: 1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale: 0.8,
                display: "none",
              },
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].map((card, index) => (
              <CardContainer key={index}>
                <Card
                  className="group"
                  initial="rest"
                  whileHover="hover"
                  animate="rest"
                >
                  <ModalImage
                    alt={card.content}
                    small={card.imageSrc}
                    medium={card.imageSrc}
                    imageBackgroundColor="transparent"
                    hideDownload={true}
                  >
                    <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto",
                        },
                        rest: {
                          opacity: 0,
                          height: 0,
                        },
                      }}
                      transition={{ duration: 0.30485 }}
                    >
                      <CardButton>Detay</CardButton>
                    </CardHoverOverlay>
                  </ModalImage>

                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                    <CardContent>{card.paperPieces}</CardContent>
                    <CardContent>{card.piecesInBox}</CardContent>
                    <CardContent>{card.paperType}</CardContent>
                    <CardContent>{card.leafSize}</CardContent>
                  </CardText>
                </Card>
              </CardContainer>
            ))}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
