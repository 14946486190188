import React, { useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import MainFeature from "components/features/TwoColWithButton.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "components/headers/light.js";
import { SectionDescription } from "components/misc/Typography";
import WhatsAppButton from "components/wp/whatsappBtn";

import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings";
import { Document, Page } from "react-pdf";
import HaspakPdf from "../pdf/test.pdf";
import { useTranslation } from "react-i18next";
import EmailIllustrationSrc from "images/haspakContact.jpg";
import styled from "styled-components";

export default () => {
  const HeadingContainer = tw.div`text-center mt-20 items-center justify-center flex`;

  const { t, i18n } = useTranslation();

  const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;

  const Image = styled.img((props) => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded bg-contain bg-no-repeat bg-center h-32 sm:h-48`,
  ]);

  return (
    <AnimationRevealPage>
      <Header />
      <WhatsAppButton />

      <HeadingContainer>
        <Image src={EmailIllustrationSrc} />
      </HeadingContainer>

      <TabGrid heading={<>{t("description.subHeader2")} </>} />
      <Footer />
    </AnimationRevealPage>
  );
};
