import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import MainFeature from "components/features/TwoColWithButton.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ScrollToTop from "react-scroll-to-top";
import haspakimg from "images/haspak2.jpg";
import { useTranslation } from "react-i18next";
import WhatsAppButton from "components/wp/whatsappBtn";
import newSectionImg from "images/image-012.jpg";
import DesignIllustration from "images/haspakHero2.jpeg";
import NewComp from "components/hero/NewComp";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl   w-5/6`;
  const { t } = useTranslation();
  const FlexRow = tw.div`flex flex-row items-center justify-center`;

  return (
    <AnimationRevealPage>
      <FlexRow>
        <ScrollToTop
          smooth
          style={{
            left: "20px",
            bottom: "10px",
          }}
        />
        <WhatsAppButton number="+90 544 471 69 66" text="Merhaba" />
      </FlexRow>
      <Hero
        heading={
          <>
            {t("description.subHeader")}
            <HighlightedText>{t("description.subHighLight")}</HighlightedText>
          </>
        }
        imageSrc={DesignIllustration}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        Socialicn={true}
        primaryButtonText="Order Now"
        watchVideoButtonText="Meet The Chefs"
      />
      <MainFeature
        subheading={<Subheading>{t("description.subDesp")}</Subheading>}
        heading={
          <>
            {t("description.subHeader")}
            <wbr />{" "}
            <HighlightedText> {t("description.subDescp2")}</HighlightedText>
          </>
        }
        description={
          <Description>
            {t("description.subLongDescp")}
            <br />
            <br />
            {t("description.subLongDescp2")}
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Latest Offers"
        imageSrc={haspakimg}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <NewComp
        paragraphText={
          <HighlightedText>{t("description.subDescp3")}</HighlightedText>
        }
        imageSrc={newSectionImg}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Order Now"
        watchVideoButtonText="Meet The Chefs"
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <TabGrid heading={<>{t("description.subHeader2")} </>} />
      <Testimonial />
      <Footer />
    </AnimationRevealPage>
  );
};
