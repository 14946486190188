import { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import test from "../pdf/haspak.pdf";
import WhatsAppButton from "components/wp/whatsappBtn";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.a`text-sm mt-6 block text-gray-500`;
const Phone = tw.a`text-sm mt-0 block text-gray-500`;
const HeroContainer = tw.div`z-1 relative px-4 items-center flex flex-col  justify-center pt-12   mx-auto`;
const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 pt-2`;

const StyledContdiv = tw.div` p-2 container flex justify-center mx-auto`;
const StyledDivFlex = tw.div`flex flex-row mx-auto`;
const StyledButton = tw.button`bg-gray-800 text-white rounded-l-md border-r border-gray-100 py-2 hover:bg-red-700 hover:text-white px-3`;
const StyledContdivs = tw.div`flex flex-row align-middle`;
const StyledButtons = tw.button`bg-gray-800 text-white rounded-r-md py-2 border-l border-gray-200 hover:bg-red-700 hover:text-white px-3`;
const StyledDownload = tw.a`bg-gray-800 text-white rounded-md py-2 mb-2 border-l border-gray-200 hover:bg-red-700 hover:text-white px-3`;

export default () => {
  const { t, i18n } = useTranslation();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);
  return (
    <StyledDiv>
      <Header />
      <WhatsAppButton />
      <HeroContainer>
        <Document file={test} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
        <StyledContdiv>
          <StyledDivFlex>
            <StyledButton type="button" onClick={goToPrevPage}>
              <StyledContdivs>
                <svg
                  class="w-5 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <p class="ml-2">{t("prev")}</p>
              </StyledContdivs>
            </StyledButton>
            <StyledButtons type="button" onClick={goToNextPage}>
              <StyledContdivs>
                <span class="mr-2">{t("next")}</span>
                <svg
                  class="w-5 ml-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </StyledContdivs>
            </StyledButtons>
          </StyledDivFlex>
        </StyledContdiv>
        <StyledDownload href={test} download>
          {t("download")}
        </StyledDownload>
      </HeroContainer>
      <Footer />
    </StyledDiv>
  );
};
