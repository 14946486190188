import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  ContentWithPaddingXl,
  Container as ContainerBase,
} from "components/misc/Layouts.js";
import {
  SectionHeading as Heading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

import hm from "images/customers/hm.png";
import ottos from "images/customers/ottos.png";
import petek from "images/customers/petek.png";

const Container = tw(ContainerBase)`bg-gray-900 -mx-8`;
const Subheading = tw(SubheadingBase)`text-center`;
const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const TestimonialContainer = tw.div`mt-16 lg:w-1/3`;
const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-32 h-24 rounded-none`;
const Quote = tw.blockquote`mt-5 text-gray-100 font-medium leading-loose`;
const CustomerName = tw.p`mt-5 text-gray-500 font-semibold uppercase text-sm tracking-wide`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-100`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

export default ({
  heading = "Referanslarımız",
  testimonials = [
    {
      imageSrc: hm,
      quote: "H&M",
      customerName: "hm",
    },
    {
      imageSrc: ottos,
      quote: "OTTO'S AG",
      customerName: "Otto's",
    },
    {
      imageSrc: petek,
      quote: "VEYSELOGLU LLC",
      customerName: "VEYSELOGLU LLC",
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <Heading>{heading}</Heading>
        <Testimonials className="w-32">
          {testimonials.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                <Image src={testimonial.imageSrc} />
                {/* <Quote>{testimonial.quote}</Quote> */}
                <CustomerName> {testimonial.customerName}</CustomerName>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
