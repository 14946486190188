import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import { SectionHeading } from "components/misc/Headings";
import { useTranslation } from "react-i18next";
import Hero from "components/hero/BackgroundAsImage.js";
import EmailIllustrationSrc from "images/haspakContact.jpg";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const HeadingContainer = tw.div`text-center mt-20 items-center justify-center flex`;

const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;

const Image = styled.img((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-32 sm:h-48`,
]);

export default () => {
  const { t, i18n } = useTranslation();
  return (
    <AnimationRevealPage>
      <Header />
      <HeadingContainer>
        <Image src={EmailIllustrationSrc} />
      </HeadingContainer>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{t("aboutUs.subHeader")}</Heading>
          </HeadingRow>
          <Text>{t("aboutUs.subDesp")}</Text>
        </ContentWithPaddingXl>
        <Testimonial />
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
