import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import whatsapp from "images/whatsapp.png";

const WhatsAppBtn = () => {
  return (
    <div>
      {isBrowser && (
        <BrowserView>
          <a
            href="https://web.whatsapp.com/send?phone=+90%20544%20471%2069%2066&text=Merhaba"
            target="_blank"
            rel="noopener noreferrer"
            className="whatsapp"
            style={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
              zIndex: "999",
            }}
          >
            <img src={whatsapp} width={50} alt="whatsapp" />
          </a>
        </BrowserView>
      )}
      {isMobile && (
        <MobileView>
          <a
            href="https://wa.me/+905444716966?text=Merhab"
            target="_blank"
            rel="noopener noreferrer"
            className="whatsapp"
            style={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
              zIndex: "999",
            }}
          >
            <img src={whatsapp} width={30} alt="whatsapp" />
          </a>
        </MobileView>
      )}
    </div>
  );
};

export default WhatsAppBtn;
