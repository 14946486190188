import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import { useTranslation } from "react-i18next";
import EmailIllustrationSrc from "images/haspakContact.jpg";
import WhatsAppButton from "components/wp/whatsappBtn";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.a`text-sm mt-6 block text-gray-500`;
const Phone = tw.a`text-sm mt-0 block text-gray-500`;

export default () => {
  const { t, i18n } = useTranslation();

  return (
    <AnimationRevealPage>
      <Header />
      <WhatsAppButton />
      <ContactUsForm />
      <ContactDetails
        cards={[
          {
            title: `${t("contactUs.contactIstanbul")}`,
            description: (
              <>
                <Address>
                  <AddressLine>
                    Değirmenbahçe Cad. Yenibosna Merkez Mah. İstwest Sitesi A2A
                    Blok No: 17 Zemin kat D:3
                  </AddressLine>

                  <AddressLine>Yenibosna- Bahçelievler-İstanbul</AddressLine>
                </Address>
                <Email href="mailto:info@haspakmendil.com">
                  Email: info@haspakmendil.com
                </Email>
                <Phone href="tel: +90 544 471 69 66">
                  Telefon: +90 544 471 69 66
                </Phone>
              </>
            ),
          },
          {
            title: `${t("contactUs.contactGaziantep")}`,
            description: (
              <>
                <Address>
                  <AddressLine>
                    Burak Mahallesi. Trikotajcılar Sitesi Yanı. 06016 Nolu
                    Cadde. No:10 PK: 27580
                  </AddressLine>
                  <AddressLine>Şehitkamil / GAZİANTEP</AddressLine>
                </Address>
                <Email href="mailto: info@haspakmendil.com">
                  Email: info@haspakmendil.com
                </Email>
                <Phone href="tel: +90 342 241 10 10">
                  {" "}
                  Telefon: +90 342 241 10 10
                </Phone>
                <Phone href="tel:+90 342 241 11 09">
                  {" "}
                  Faks: +90 342 241 11 09
                </Phone>
              </>
            ),
          },
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
