const images = {
  mendil1000: require("../../images/productsSmaller/1 (1).png"),
  mendil1001: require("../../images/productsSmaller/1 (2).png"),
  mendil1002: require("../../images/productsSmaller/1 (3).png"),
  mendil1003: require("../../images/productsSmaller/1 (4).png"),
  mendil1004: require("../../images/productsSmaller/1 (5).png"),
  mendil1005: require("../../images/productsSmaller/1 (6).png"),
  mendil1006: require("../../images/productsSmaller/1 (7).png"),
  mendil1007: require("../../images/productsSmaller/1 (8).png"),
  mendil1008: require("../../images/productsSmaller/1 (9).png"),
  mendil1009: require("../../images/productsSmaller/1 (10).png"),
  mendil1010: require("../../images/productsSmaller/1 (11).png"),
  mendil1011: require("../../images/productsSmaller/1 (12).png"),
  mendil1012: require("../../images/productsSmaller/1 (13).png"),
  mendil1013: require("../../images/productsSmaller/1 (14).png"),
  mendil1014: require("../../images/productsSmaller/1 (15).png"),
  mendil1015: require("../../images/productsSmaller/1 (16).png"),
  mendil1016: require("../../images/productsSmaller/1 (17).png"),
  mendil1017: require("../../images/productsSmaller/1 (18).png"),
  mendil2001: require("../../images/productsSmaller/1 (19).png"),
  mendil2002: require("../../images/productsSmaller/1 (20).png"),
  mendil2003: require("../../images/productsSmaller/1 (21).png"),
  mendil2004: require("../../images/productsSmaller/1 (22).png"),
  mendil2005: require("../../images/productsSmaller/1 (23).png"),
  mendil2006: require("../../images/productsSmaller/1 (24).png"),
  mendil2007: require("../../images/productsSmaller/1 (25).png"),
  mendil2008: require("../../images/productsSmaller/1 (26).png"),
  mendil1101: require("../../images/productsSmaller/1 (27).png"),
  mendil2502: require("../../images/productsSmaller/1 (28).png"),
  mendil3503: require("../../images/productsSmaller/1 (29).png"),
  mendil2504: require("../../images/productsSmaller/1 (30).png"),
  mendil3008: require("../../images/productsSmaller/1 (37).png"),
  mendil2501: require("../../images/productsSmaller/1 (38).png"),
  mendil3502: require("../../images/productsSmaller/1 (39).png"),
  mendil2503: require("../../images/productsSmaller/1 (40).png"),
  mendil3504: require("../../images/productsSmaller/1 (41).png"),
  mendil2505: require("../../images/productsSmaller/1 (42).png"),
  mendil2506: require("../../images/productsSmaller/1 (43).png"),
  mendil2507: require("../../images/productsSmaller/1 (44).png"),
  mendil2508: require("../../images/productsSmaller/1 (45).png"),
  mendil2509: require("../../images/productsSmaller/1 (46).png"),
  mendil2510: require("../../images/productsSmaller/1 (47).png"),
  mendil2511: require("../../images/productsSmaller/1 (48).png"),
  mendil2512: require("../../images/productsSmaller/1 (49).png"),
  mendil2513: require("../../images/productsSmaller/1 (50).png"),
  mendil2514: require("../../images/productsSmaller/1 (51).png"),
  mendil2515: require("../../images/productsSmaller/1 (52).png"),
  mendil2516: require("../../images/productsSmaller/1 (53).png"),
  mendil2517: require("../../images/productsSmaller/1 (54).png"),
  mendil2518: require("../../images/productsSmaller/1 (55).png"),
  mendil2519: require("../../images/productsSmaller/1 (56).png"),
  mendil2520: require("../../images/productsSmaller/1 (57).png"),
  mendil2521: require("../../images/productsSmaller/1 (58).png"),
  mendil2522: require("../../images/productsSmaller/1 (59).png"),
  mendil2523: require("../../images/productsSmaller/1 (60).png"),
  mendil2524: require("../../images/productsSmaller/1 (61).png"),
  mendilKutu1: require("../../images/productsSmaller/1 (18).png"),
  mendilKutu2: require("../../images/productsSmaller/1 (20).png"),
  mendilKutu3: require("../../images/productsSmaller/1 (45).png"),
  mendilKutu4: require("../../images/productsSmaller/1 (58).png"),
};

export default images;
